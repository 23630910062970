import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import HandDrawing from "../../../../../components/hand-drawing"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Note from "../../../../../components/note"
import Stack from "../../../../../components/stack"

import SampleImage from "../../../../../images/kurse/titel-fruehling.png"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      as="form"
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/jahreszeiten/01-jahreszeitengedicht/handschrift/zusammenfassung" />
      }
    >
      <Seo title="Handschrift" />
      <Box>
        <Stack>
          <Heading as="h1" level={2}>
            Hölderlins Handschrift nachzeichnen
          </Heading>
          <Paragraph>
            Zu Hölderlins Zeit gab es noch keine Computer und selbst der
            Vorgänger des Computers, die Schreibmaschine, wurde gerade erst
            erfunden. Hölderlin schrieb mit einer Feder, die er beim Schreiben
            regelmäßig in ein Tintenfass tunken musste. Deshalb finden sich auf
            seinen Texten auch so viele Tintenkleckse. So ein von Hand
            beschriebenes Papier nennt man Manuskript, vom latenischen manu
            (Hand) und scriptum (Geschriebenes).
          </Paragraph>
          <Note variant="task">
            <p>
              Versuche, den Titel des Gedichts in der Handschrift nachzufahren.
            </p>
            <p>
              Auf dem Handy oder Tablet kannst du dafür deinen Finger verwenden.
              Am Computer hältst du die linke Maustaste gedrückt und ziehst den
              Mauszeiger über das Bild.
            </p>
          </Note>
          <HandDrawing
            image={SampleImage}
            radius={3}
            ratio={0.3}
            onChange={(ref) => {
              setAnswer({
                courseId: "jahreszeiten",
                chapterId: "01-jahreszeitengedicht",
                taskId: "handschrift",
                answer: ref.getSaveData(),
              })
            }}
          />
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
